import React, { Component } from 'react';
// import '../../src/css/style.css';

class AboutPage extends Component {

  componentDidMount() {
    window.scrollTo(0,0)
  }

  render() {
    return (
      <section className="section" id="about-section" style={{backgroundColor: "black"}}>
        <div className="container">
        <h4 className="main-heading">About Me</h4>
          {/* <div className="row"> */}

              <p id="about-sub-header" style={{color: "lightgreen"}}>
                My name is Ryan.  I have a Degree in&nbsp;
                <b style={{color: "pink"}} title="confetti pops">Computer Science</b>.
              </p>
              <p className="body-text" style={{color: "limegreen", textAlign: "center"}}>
                HOBBIES (+/- 1): playing games, coding,
                hiking, camping, fishing, puzzles, exploring new places.
              </p>
          {/* </div> */}
          <div className="container">
              {/* since im using webpack, i must use require for images */}
              <img src={require("../images/burger.PNG")} alt="About Me" id="about-img" />
            </div>
        </div>
      </section>
      
    );
  }

}

export default AboutPage;
